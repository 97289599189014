import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import Image from '~components/Image'
import resolveLink from '~utils/resolveLink'
import { OptionSummary, VariantIcon } from '~styles/global'


const TileProduct = ({ className, product }) => {
	const [hover, setHover] = useState(false)
	let parents = useStaticQuery(graphql`
		{
			products: allSanityProduct {
				nodes {
						slug{
							current
						}
						optionSettings {
						relatedOption
						swatch{
							...imageWithAlt
						}
					}
				}
			}
		}
	`)

	parents = parents.products.nodes

	let parent = parents.find(prod => prod.slug.current === product.content.shopify.productSlug)

	const glaze = product?.options?.find(option => option.name === 'Glaze')?.value

	const relatedOption = parent?.optionSettings?.find(setting => {
		const settings = setting.relatedOption.split(':')
		const optionTitle = settings[1]
		return optionTitle === glaze
	})

	return (
		<Wrap 
			className={className}
			to={resolveLink(product)} 
			onMouseEnter={()=> setHover(true)}
			onMouseLeave={()=> setHover(false)}
		>	
			<ImageContainer>
				{(product?.content?.saleMessage || !product?.content?.shopify?.available) && 
					<Label>
						{product?.content?.saleMessage ? 
							product?.content?.saleMessage
							: 
							!product?.content?.shopify?.available ? 'Out of stock' 
								: 
								null
						}
					</Label>	
				}
				<Thumb 
					image={product?.content?.thumb} 
					aspectRatio={1} 
					css={css`img{transform: ${hover && !product?.content?.thumbHover ? 'scale3d(1.02, 1.02, 1)' : 'scale3d(1, 1, 1)'};}`}
				/>
				<ThumbHover 
					image={product?.content?.thumbHover} 
					aspectRatio={1} 
					css={css`
						opacity: ${hover ? '1' : '0'};
					`}
				/>
			</ImageContainer>
			<Title>{product?.content?.shopify?.productTitle}{product?.content?.shopify?.productTitle === 'Voucher' ? ` ($${product?.content?.shopify?.price})` : ''}</Title>
			<Details>
				<h3>{product?.content?.summary}</h3>
				<h3>{product?.content?.dimensions}</h3>
				{glaze &&
					<TileOptionSummary >
						<VariantIcon image={relatedOption?.swatch} aspectRatio={1} />
						<h3>{glaze}</h3>
					</TileOptionSummary>
				}
			</Details>
		</Wrap>
	)
}

const Wrap = styled(Link)`
	padding: var(--m);
`
const ImageContainer = styled.div`
	overflow: hidden;
	margin-bottom: var(--l);
	position: relative;
`
const Label = styled.h4`
	position: absolute;
	top: var(--xs);
	left: var(--xs);
	background: var(--beige);
	padding: calc(var(--xxs) + 2px) var(--xs) var(--xxs);
	border: 1px solid var(--black);
	z-index: 1;
`
const Thumb = styled(Image)`
	img{
		transition: transform 0.3s;
	}
`
const ThumbHover = styled(Image)`
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	transition: opacity 0.3s;
`
const Title = styled.h3`
	text-align: center;
	margin-bottom: var(--l);
`
const Details = styled.div`
	text-align: center;
	margin-bottom: var(--xs);
`

const TileOptionSummary = styled(OptionSummary)`
	justify-content: center;
	padding-top: var(--l)
`

TileProduct.propTypes = {
	className: PropTypes.string,
	product: PropTypes.object,
}

export default TileProduct